import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Home from '../components/Home';
import StartButton from '../components/StartButton';
import Section from '../components/Section';
import EmptySection from '../components/EmptySection';
import FixedSection from '../components/FixedSection';
import Credits from '../components/Credits';
import Sponsors from '../components/Sponsors';
import Speaker from '../components/Speaker';
import { window } from 'browser-monads';

const IndexPage = (props) => {
    const homeData = useStaticQuery(graphql`
        query homeQuery {
            allMarkdownRemark {
                edges {
                    node {
                        frontmatter {
                            type
                            position_second
                            background
                            position
                            episode
                            quote
                            name
                            title
                            pre_title
                            second_title
                            intro_title
                            urgency_first
                            urgency_second
                            intro_second_title
                            first_title
                            title_home
                            slug
                            button_text
                            order
                            nff_selection {
                                childImageSharp {
                                    fluid(quality: 100, maxWidth: 600) {
                                        ...GatsbyImageSharpFluid_noBase64
                                    }
                                }
                            }
                            prix_selection {
                                childImageSharp {
                                    fluid(quality: 100, maxWidth: 600) {
                                        ...GatsbyImageSharpFluid_noBase64
                                    }
                                }
                            }
                            seo_image {
                                childImageSharp {
                                    fixed(quality: 100, width: 1200) {
                                        src
                                    }
                                }
                            }
                            image {
                                childImageSharp {
                                    fluid(quality: 88, maxWidth: 1000) {
                                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                    }
                                }
                            }
                        }
                        html
                    }
                }
            }
        }
    `);

    const [credits, setCredits] = useState();

    useEffect(() => {
        let credits = require(`../data/credits.json`);
        const throttle = require(`lodash.throttle`);

        setCredits(credits);

        let docH = document.body.clientHeight;

        window.addEventListener(
            `scroll`,
            throttle(function (e) {
                if (window.scrollY > docH / 2) {
                    showSecond();
                } else {
                    showFirst();
                }
            }, 100)
        );
    }, []);

    const episodes = [];
    const speakers = [];
    let home = {};

    const [firstVideo, setFirstVideo] = useState({ render: 1, show: 1 });
    const [secondVideo, setSecondVideo] = useState({ render: 0, show: 0 });

    const [myState, _setMyState] = React.useState({
        firstVideo: { render: 1, show: 1 },
        secondVideo: { render: 0, show: 0 },
    });

    const myStateRef = React.useRef(myState);
    const setMyState = (data) => {
        myStateRef.current = data;
        _setMyState(data);
    };

    const showFirst = () => {
        if (!myStateRef.current.firstVideo.show) {
            firstVideo.show = true;
            setMyState({
                ...myStateRef.current,
                firstVideo: { render: 1, show: 1 },
            });
        }
        if (myStateRef.current.secondVideo.show) {
            setMyState({
                ...myStateRef.current,
                secondVideo: { render: 1, show: 0 },
            });
        }
    };

    const showSecond = () => {
        if (myStateRef.current.firstVideo.show) {
            setMyState({
                ...myStateRef.current,
                firstVideo: { render: 1, show: 0 },
            });
        }

        if (!myStateRef.current.secondVideo.show) {
            setMyState({
                ...myStateRef.current,
                secondVideo: { render: 1, show: 1 },
            });
        }
    };

    homeData.allMarkdownRemark.edges.forEach((edge) => {
        const { type } = edge.node.frontmatter;
        if (type === `episode`) {
            episodes.push(edge.node.frontmatter);
        } else if (type === `landing`) {
            home = { ...edge.node.frontmatter, content: edge.node.html };
        } else if (type === `speaker`) {
            speakers.push(edge.node);
        }
    });

    // We order the episodes and speakers based on their order attribute
    episodes.sort((a, b) => a.order - b.order);

    speakers.sort((a, b) => {
        return a.frontmatter.order - b.frontmatter.order;
    });

    return (
        <Layout image={home.seo_image.childImageSharp.fixed.src}>
            <div className="home-wrapper">
                <Section
                    bg={{ type: `color`, color: `#000` }}
                    classes="overflow-fix text-center min-max-height padding-x-small"
                >
                    <div className="main-title-wrapper">
                        <h1 className="main-title uppercase">
                            {home.first_title}
                            <br />-<br />
                            {home.second_title}
                        </h1>
                    </div>
                    <div className="intro-content mb-big">
                        <p>{home.urgency_first}</p>
                        <p>{home.urgency_second}</p>
                    </div>
                    <div className="selections">
                        <div>
                            <Img
                                className="selection-logo nff-logo"
                                fluid={home.nff_selection.childImageSharp.fluid}
                            />
                        </div>
                        <div>
                            <Img
                                className="selection-logo"
                                fluid={
                                    home.prix_selection.childImageSharp.fluid
                                }
                            />
                        </div>
                    </div>
                </Section>

                <Section
                    bg={{ type: `color`, color: `transparent` }}
                    classes="padding-x-big min-height text-center text-drop-shadow"
                >
                    {episodes.map((episode, index) => (
                        <Episode
                            key={`episode-${index}`}
                            episode={episodes[index]}
                        />
                    ))}
                </Section>

                <FixedSection
                    bg={{ type: `color`, color: `#c7beb3` }}
                    classes={`overflow-hidden ${
                        myState.firstVideo.show ? `show-first` : `show-second`
                    }`}
                >
                    <iframe
                        className={`vimeo-bg first`}
                        src="https://player.vimeo.com/video/430025677?background=1&autoplay=1&loop=1&byline=0&title=0"
                        frameBorder="0"
                        data-hj-allow-iframe="true"
                    />

                    {myState.secondVideo.render ? (
                        <iframe
                            className="vimeo-bg second"
                            src="https://player.vimeo.com/video/430030545?background=1&autoplay=1&loop=1&byline=0&title=0"
                            frameBorder="0"
                            data-hj-allow-iframe="true"
                        />
                    ) : null}
                </FixedSection>

                <Section
                    bg={{ type: `color`, color: `#000` }}
                    classes="padding-x-small normal"
                >
                    <h1 className="main-title uppercase mt-small text-center">
                        {home.intro_title}
                    </h1>

                    <div className="intro-content mb-small mt-big">
                        <div
                            dangerouslySetInnerHTML={{ __html: home.content }}
                        />
                        <Link className="link text-center" to="/about">
                            Read more
                        </Link>
                    </div>
                </Section>

                {speakers.map((speaker, index) => (
                    <Speaker speaker={speaker} key={`speaker-${index}`} />
                ))}

                <Section
                    bg={{ type: `color`, color: `transparent` }}
                    classes="text-center credits normal"
                >
                    <h1 className="main-title uppercase mt-xl credits-title padding-x-small">
                        {home.first_title}
                    </h1>

                    <div className="credits-wrapper">
                        {credits && <Credits credits={credits} />}
                    </div>

                    <Sponsors />
                </Section>

                {/* <Home
					hostname={hostname}
					setGyro={setGyroPermission}
					gyroData={gyroData}
					recalibrate={() => recalibrate()}
					initIndex={initIndex}
				/> */}
            </div>
        </Layout>
    );
};

const Episode = ({ episode }) => {
    let epName = episode.title_home.split(`-`);
    let slug = `/${episode.slug}`;
    return (
        <div className="episode-wrapper" key={episode.video_id}>
            <span>{episode.pre_title}</span>
            <h2 className="episode-title">
                {epName.map((name, i) => {
                    return (
                        <React.Fragment key={`episode-${i}`}>
                            {i === 1 ? (
                                <>
                                    -<br />
                                </>
                            ) : null}
                            {name}
                        </React.Fragment>
                    );
                })}
            </h2>
            <Link to={slug}>{episode.button_text}</Link>
        </div>
    );
};

export default IndexPage;
