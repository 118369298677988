import React from 'react';

const Credits = ({credits}) => {
	return (
		<>
			{credits.sections && credits.sections.map(section => {
				return (
					<div className="credit-block" key={`section-${section.title}`}>
						<span>{section.title}</span>
						<ul>
							{section.credits.map(credit =>{
								return(
									<li key={`credit-${section.title}-${credit}`}>
										<span>{credit}</span>
									</li>
								);
							})}
						</ul>
					</div>
				);
			})}
		</>
	);
};

export default Credits;