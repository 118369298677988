/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const SEO_MAP = {

	//Key
	413971852: {
		title: `TRUST BLOCKCHAIN SOCIETY, Kei Kreutler`,
		desc: `As temporal replicas become frozen through boundaried and diverse practice, the viewer is left with a tribute to the inaccuracies of our era.`,
		img: `https://i.imgur.com/qpM43gg.jpg`
	},
	413973917: {
		title: `TRUST BLOCKCHAIN SOCIETY, EPISODE THREE, DECENTRALIZED ECONOMY, Kei Kreutler`,
		img: `images/seo/kei3.jpg`,
		desc: `Chartreuse ramps slow-carb microdosing 90's sartorial unicorn direct trade letterpress etsy tumeric you probably haven't heard of them gastropub cronut taiyaki.`
	},
	413972290: {
		title: `TRUST BLOCKCHAIN SOCIETY, EPISODE THREE, DECENTRALIZED ECONOMY, Kei Kreutler`,
		img: `https://i.imgur.com/XKjYTcs.jpg`,
		desc: `What starts out as yearning soon becomes debased into a dialectic of power, leaving only a sense of what could have been and the unlikelihood of a new beginning.`
	},
	413973187: {
		title: `TRUST BLOCKCHAIN SOCIETY, EPISODE THREE, DECENTRALIZED ECONOMY, Kei Kreutler`,
		img: `https://i.imgur.com/54xngsT.jpg`,
		desc: `Sustainable pitchfork typewriter kale chips, hoodie helvetica sartorial keffiyeh disrupt 90's authentic gastropub pickled tattooed DIY.`
	},
	413972814: {
		title: `TRUST BLOCKCHAIN SOCIETY, EPISODE THREE, DECENTRALIZED ECONOMY, Kei Kreutler`,
		desc: `What starts out as yearning soon becomes debased into a dialectic of power, leaving only a sense of what could have been and the unlikelihood of a new beginning.`,
		img: `https://i.imgur.com/XKjYTcs.jpg`
	},

	// Arthur
	413964098: {
		title: `TRUST BLOCKCHAIN SOCIETY, EPISODE TWO, Libertarian Blindspot, Arthur Roing Baer`,
		desc: `As momentary derivatives become distorted through diligent and academic practice, the viewer is left with a hymn to the possibilities of our condition.`,
		img: `https://i.imgur.com/OQ014s4.jpg`
	},
	413977456: {
		title: `TRUST BLOCKCHAIN SOCIETY, EPISODE TWO, Libertarian Blindspot, Arthur Roing Baer`,
		desc: `What starts out as contemplation soon becomes debased into a cacophony of futility,`,
		img: `https://i.imgur.com/Ss6hoBk.jpg`
	},

	// Jaromil

	413970858: {
		title: `TRUST BLOCKCHAIN SOCIETY, EPISODE TWO, NEW ARCHITECTURES OF TRUST, Jaromil Rojo`,
		desc: `What starts out as contemplation soon becomes debased into a cacophony of futility,`,
		img: `https://i.imgur.com/VZy4UHk.jpg`
	},
	413975608: {
		title: `TRUST BLOCKCHAIN SOCIETY, EPISODE TWO, NEW ARCHITECTURES OF TRUST, Jaromil Rojo`,
		desc: `As momentary derivatives become distorted through diligent and academic practice, the viewer is left with a hymn to the possibilities of our condition.`,
		img: `https://i.imgur.com/pRpN3IM.jpg`
	},
	413967889: {
		title: `TRUST BLOCKCHAIN SOCIETY, EPISODE TWO, NEW ARCHITECTURES OF TRUST, Jaromil Rojo`,
		desc: `What starts out as contemplation soon becomes debased into a cacophony of futility,`,
		img: `https://i.imgur.com/pRpN3IM.jpg`
	},
	413974249: {
		title: `TRUST BLOCKCHAIN SOCIETY, EPISODE TWO, NEW ARCHITECTURES OF TRUST, Jaromil Rojo`,
		desc: `As momentary derivatives become distorted through diligent and academic practice, the viewer is left with a hymn to the possibilities of our condition.`,
		img: `https://i.imgur.com/VZy4UHk.jpg`
	},
	413976017: {
		title: `TRUST BLOCKCHAIN SOCIETY, EPISODE TWO, NEW ARCHITECTURES OF TRUST, Jaromil Rojo`,
		desc: `As momentary derivatives become distorted through diligent and academic practice, the viewer is left with a hymn to the possibilities of our condition.`,
		img: `https://i.imgur.com/VZy4UHk.jpg`
	},
	413975000: {
		title: `TRUST BLOCKCHAIN SOCIETY, EPISODE TWO, NEW ARCHITECTURES OF TRUST, Jaromil Rojo`,
		desc: `What starts out as contemplation soon becomes debased into a cacophony of futility,`,
		img: `https://i.imgur.com/VZy4UHk.jpg`
	}
}

function SEO({ description, lang, meta, title, videoId, hostname }) {
	const { site } = useStaticQuery(
		graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
	);

	let hosturl = process.env.DEPLOY_PRIME_URL || process.env.URL;

	const metaDescription = description || site.siteMetadata.description;
	
	let share = {
		title: `Video doesn't exist.`,
		desc: `Please try another video`,
		img: `https://i.imgur.com/54xngsT.jpg`
	};




	if(videoId){
		share.title = SEO_MAP[videoId].title;
		share.desc = SEO_MAP[videoId].desc;
		share.img = SEO_MAP[videoId].img;
	}

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={share.title}
			meta={[
				{
					name: `description`,
					content: share.desc,
				},
				{
					property: `og:title`,
					content: share.title,
				},
				{
					property: `og:image`,
					content: share.img
				},
				{
					property: `og:description`,
					content: share.desc,
				},
				{
					property: `og:type`,
					content: `video`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.author,
				},
				{
					name: `twitter:title`,
					content: share.title,
				},
				{
					name: `twitter:description`,
					content: share.desc,
				},{
					name: `og:description`,
					content: share.desc,
				},
				{
					name: `og:image:width`,
					content: `1200`
				},
				{
					name: `og:image:height`,
					content: `620`
				}
			].concat(meta)}
		/>
	);
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
};

export default SEO;
