import React from "react";
import BackgroundImage from 'gatsby-background-image'


const FixedSection = ({bg, children, classes}) => {
	return (
		<>
			<section className={`${classes} fixed-section`} style={{background: bg.color}}>
				{children}
			</section>
		</>
	);
};

export default FixedSection;