import React, { useState } from 'react';
import Section from './Section';

const Speaker = ({ speaker }) => {
    const [isActive, setIsActive] = useState(false);

    const toggleBlur = () => {
        setIsActive(!isActive);
        typeof window !== 'undefined' &&
            window.gtag('event', 'Tap speaker', {});
    };

    let speakerName = speaker.frontmatter.title.split(` `);

    return (
        <Section
            bg={{
                type: `image`,
                image: speaker.frontmatter.image,
                bgColor: speaker.frontmatter.background,
            }}
            classes={`p-relative ${isActive ? `clear` : `blurry`}`}
            clicable={true}
            clickEvent={() => toggleBlur()}
        >
            <div className="speaker-info">
                <h3 className="speaker-title">
                    {speakerName[0]}
                    <br />
                    {speakerName[1]}
                    {speakerName[2] ? ` ${speakerName[2]}` : ``}
                </h3>

                <span>{speaker.frontmatter.position}</span>
            </div>
            <div className="bio-quote-wrapper">
                <span>{speaker.frontmatter.quote}</span>
                <div
                    className="speaker-bio"
                    dangerouslySetInnerHTML={{ __html: speaker.html }}
                />
            </div>
        </Section>
    );
};

export default Speaker;
