import React from 'react';
import { useStaticQuery, graphql} from 'gatsby';
import Img from "gatsby-image"

const Sponsors = (props) => {

	const sponsorData = useStaticQuery(graphql`
        query sponsorsQuery {
            allMarkdownRemark(filter: {frontmatter: {type: {eq: "sponsor"}}}) {
                edges {
                    node {
                        frontmatter {
							type
							role
                            image {
                                childImageSharp {
                                    fluid(quality: 100,  maxWidth: 600) {
                                        ...GatsbyImageSharpFluid_noBase64
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
	`);

	const sponsors = sponsorData.allMarkdownRemark.edges;

	let sections = [];
	sponsors.forEach(sponsor => {
		let role = sponsor.node.frontmatter.role;
		let target = sections.find(section => section.role === role);

		if(!target){
			let title = ``;
			if(role === `funder`){
				title = `Funded by`;
			} else if (role === `producer`){
				title = `Produced by`;
			} else if (role === `designer`){
				title = `Design studio`;
			}

			sections.push({
				role,
				list: [],
				title
			});

			target = sections.find(section => section.role === role);
		}

		target.list.push(sponsor.node.frontmatter.image);

	});

	sections.sort((a,b) => {
		if(a.role === `designer`){
			return -1;
		}

		if(b.role === `designer`){
			return 1;
		}
	})

	return (
		<div className={`sponsors-wrapper ${props.classes ? props.classes : ``}`}>

			{
				sections.map(section => (
					<Section 
						key={section.role} 
						type={section.role} 
						text={section.title} 
						list={section.list} 
					/>
				))
			}

		</div>
	);
};

const Section = ({text, list, type}) => {
	return (
		<div className={`sponsors-section sponsors-${type}`}>
			<span>{text}</span>
			<div className="sponsors-images">
				{list.map((sponsor, idx) => {
					return (
						<Img 
							key={`sponsor-${idx}`} 
							className="sponsor-image" 
							fluid={sponsor.childImageSharp.fluid} 
						/>
					);
				})}
			</div>
		</div>
	);
}

export default Sponsors;